import React, { FunctionComponent, ReactElement} from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import {
    SEO,
    ProviderSummary1,
    ProviderSummary2,
    ProviderSummary3,
    ProviderPortalView,
    GeneralJumbotron,
    ScheduleDemo,
    CallToAction,
} from '../components';
import { MainLayout } from '../layouts';
import { ProviderImagesQuery } from '../shared/interfaces';

const ProviderPage: FunctionComponent = (): ReactElement => {
    const queryResult = useStaticQuery<ProviderImagesQuery>(graphql`
        query {
            providerBackgroundImage: file(name: {eq: "providerBackground"}, extension: {eq: "jpg"}) {
                publicURL
            }
        }
    `);

    return (
        <MainLayout>
            <SEO title="PCH | Provider"/>
            <GeneralJumbotron
                title="Provider"
                backgroundImageURL={queryResult?.providerBackgroundImage.publicURL}
                backgroundText="Provider"
            />
            <ProviderSummary1/>
            <ProviderSummary2/>
            <ProviderSummary3/>
            {/* <ProviderPortalView/> */}
            {/* <CallToAction/> */}
            <ScheduleDemo/>
        </MainLayout>
    );
};

export default ProviderPage;
